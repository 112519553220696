import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Product from './common/product';
import Productm from './common/product eyeM';
import Menseye from './pages/Sunglasses';
import HomePage from './pages/home';
import NewArrival from './pages/new_arrivals';
import Productf from './common/product copy';
import Producteyef from './common/product eyeF';
import Meneye from './pages/Eyewears';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/js/bootstrap';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
let NavRoutes = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: 'Collection/Sunglasses',
    element: <Menseye />,
  },
  {
    path: 'mens/:model',
    element: <Product />,
  },
  {
    path: 'womens/:model',
    element: <Productf />,
  },
  {
    path: 'women/:model',
    element: <Producteyef />,
  },
  {
    path: 'Collection/Eyewears',
    element: <Meneye />,
  },
  {
    path: 'men/:model',
    element: <Productm />,
  },
  {
    path: 'Collection/New Arrival',
    element:<NewArrival/>
  }
  
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={NavRoutes} />
  </React.StrictMode>
);

reportWebVitals();
