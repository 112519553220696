import React, { useEffect,useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import './product.css';
import Header from './head';
import WhatsAppButton from '../common/wpbtn'


const Producteyef = () => {
    const { model } = useParams();

    // Example colorCodes object mapping models to their color codes
    const colorCodes = {
        '1013':['C1','C2','C3'],
        '1027':['C1','C2','C3','C4'],
        '1029':['C1','C2','C3','C4','C5'],
        '1032':['C1','C2','C3','C4','C5'],
        '2205':['C3','C5','C6','C10'],
        '2256':['C1','C2','C4'],
        '2501':['C1','C5','C7','C8'],
        '3011':['C1','C3','C4','C6'],
        '3127':['CGRN','CTSP'],
        '5848':['C1','C3','C4','C7'],
        '6013':['C1','C4','C5','C6','C7'],
        '6016':['C1','C2','C3','C5'],
        '8273':['C1','C2','C3','C4'],
        '8868':['C1','C2','C3'],
    };

    const frameSize = {
        '1013':'52-21-145',
        '1027':'51-18-140',
        '1029':'48-21-145',
        '1032':'48-21-145',
        '2205':'51-19-148',
        '2256':'53-20-148',
        '2501':'54-20-148',
        '3011':'54-17-145',
        '3127':'52-19-148',
        '5848':'54-18-143',
        '6013':'54-17-140',
        '6016':'55-17-140',
        '8273':'53-17-139',
        '8868':'53-17-138',
    };

    const genderId = {
       '1013':'FEMALE',
        '1027':'FEMALE',
        '1029':'FEMALE',
        '1032':'FEMALE',
        '2205':'FEMALE',
        '2256':'FEMALE',
        '2501':'FEMALE',
        '3011':'FEMALE',
        '3127':'FEMALE',
        '5848':'FEMALE',
        '6013':'FEMALE',
        '6016':'FEMALE',
        '8273':'FEMALE',
        '8868':'FEMALE',
    };

    const [mainImage, setMainImage] = useState(`https://13-century.com/files/women/${model}/${colorCodes[model]?.[0]}.webp`);
  const [selectedColor, setSelectedColor] = useState(colorCodes[model]?.[0] || '');
  const [indiFrame] = useState(frameSize[model] || '');
  const [gdId] = useState(genderId[model] || '');

  const imgRef = useRef(null);
  const resultRef = useRef(null);
  const lensRef = useRef(null);

  useEffect(() => {
    const img = imgRef.current;
    const result = resultRef.current;
    let lens = document.createElement('div');
    lens.setAttribute('class', 'img-zoom-lens');
    lensRef.current = lens;
    img.parentElement.insertBefore(lens, img);

    const cx = result.offsetWidth / lens.offsetWidth;
    const cy = result.offsetHeight / lens.offsetHeight;

    result.style.backgroundImage = `url('${img.src}')`;
    result.style.backgroundSize = `${img.width * cx}px ${img.height * cy}px`;

    const moveLens = (e) => {
      e.preventDefault();
      const pos = getCursorPos(e);
      let x = pos.x - lens.offsetWidth / 2;
      let y = pos.y - lens.offsetHeight / 2;

      if (x > img.width - lens.offsetWidth) x = img.width - lens.offsetWidth;
      if (x < 0) x = 0;
      if (y > img.height - lens.offsetHeight) y = img.height - lens.offsetHeight;
      if (y < 0) y = 0;

      lens.style.left = `${x}px`;
      lens.style.top = `${y}px`;

      result.style.backgroundPosition = `-${x * cx}px -${y * cy}px`;
    };

    const getCursorPos = (e) => {
      const a = img.getBoundingClientRect();
      const x = e.pageX - a.left - window.pageXOffset;
      const y = e.pageY - a.top - window.pageYOffset;
      return { x, y };
    };

    // Ensure lens moves on both mouse and touch events
    lens.addEventListener('mousemove', moveLens);
    img.addEventListener('mousemove', moveLens);
    lens.addEventListener('touchmove', moveLens);
    img.addEventListener('touchmove', moveLens);

    return () => {
      lens.removeEventListener('mousemove', moveLens);
      img.removeEventListener('mousemove', moveLens);
      lens.removeEventListener('touchmove', moveLens);
      img.removeEventListener('touchmove', moveLens);
    };
  }, [mainImage]);

  // Reapply zoom when image is loaded
  const handleImageLoad = () => {
    const img = imgRef.current;
    const result = resultRef.current;
    const lens = lensRef.current;

    const cx = result.offsetWidth / lens.offsetWidth;
    const cy = result.offsetHeight / lens.offsetHeight;

    result.style.backgroundImage = `url('${img.src}')`;
    result.style.backgroundSize = `${img.width * cx}px ${img.height * cy}px`;
  };

  const handleImageClick = (colorCode) => {
    const imageUrl = `https://13-century.com/files/women/${model}/${colorCode}.webp`;
    setMainImage(imageUrl);
    setSelectedColor(colorCode);
  };

  return (
    <main className=''>
      <Header />
      <div className="product-item-pdc">
        <div className="wrapper-img">
          <div className="img-box">
            <img id="pd-image" ref={imgRef} src={mainImage} alt={model} onLoad={handleImageLoad} />
            <div className="img-zoom-result" ref={resultRef}></div>
          </div>
        </div>
        <div id="pdcx" className="col">
          <div className="product-item-info position-relative">
            <div className="product-title">
              <div className="d-grid">
                <h4 className="fth4">
                  Model number : <span><p className="textt"> &nbsp; {model}</p></span>
                </h4>
              </div>
              <h6 className="text-items">
                Color : <span><p className="text">&nbsp;{selectedColor}</p></span>
              </h6>
              <div className="option-group container-fluid">
                <div className="d-flex into g-3">
                  {colorCodes[model]?.map((colorCode) => (
                    <div className="col" key={colorCode}>
                      <div
                        className="carousel-cell productlist-card"
                        // onMouseEnter={() => handleImageClick(colorCode)}
                        onClick={() => handleImageClick(colorCode)}
                      >
                        <img className="color-img" src={`https://13-century.com/files/women/${model}/${colorCode}.webp`} alt={model} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text text-items" style={{ width: 'max-content' }}>
                <h6 className="d-flex text-items">
                  Gender : <span><p className="text">&nbsp;{gdId}</p></span>
                </h6>
                <h6 className="d-flex">
                  Frame Measurement : <span><p className="text">&nbsp;{indiFrame}</p></span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppButton/>
    </main>
    );
};

export default Producteyef;
