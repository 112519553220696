import React, { useState, useEffect } from 'react';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import '../mens.collection.css';
import { Link } from 'react-router-dom';
import Header from '../common/head';
import Footer from '../common/footer';
import WhatsAppButton from '../common/wpbtn';


const Menseye = () => {
  const [products, setProducts] = useState([]);
  const [hoveredImage, setHoveredImage] = useState({});
  const models = {
    '0759': ['C1', 'C2', 'C3', 'C4'],
    '2002M': ['C1', 'C5'],
    '2155': ['C2', 'C4', 'C5'],
    '2370': ['C1', 'C2', 'C3', 'C5', 'C6'],
    '6324': ['C37', 'C93'],
    '6334': ['C1', 'C3', 'C4'],
    '6608': ['C1', 'C3', 'C4', 'C6'],
    '8070': ['C2', 'C3'],
    '9833': ['C1', 'C4', 'C5', 'C6'],
    '9834': ['C1', 'C2', 'C5', 'C6'],
    '29035': ['C3', 'C4', 'C6'],
    '2170': ['C54', 'C56', 'C106'],
    '2002': ['C1', 'C5'],
    '6337': ['C1', 'C2', 'C35', 'C36'],
    '6351': ['C1', 'C2', 'C8'],
    '7005': ['C1', 'C10', 'C15'],
    '7436': ['C3', 'C4', 'C5'],
    '7479': ['C1', 'C2', 'C4', 'C9'],
    '8816': ['C1', 'C4', 'C5'],

  };
  const genderId = {
    '0759': 'mens',
    '29035': 'mens',
    '6608': 'mens',
    '6324': 'mens',
    '2002M': 'mens',
    '8070': 'mens',
    '6334': 'mens',
    '2155': 'mens',
    '9834': 'mens',
    '9833': 'mens',
    '2370': 'mens',
    '2170': 'womens',
    '2002': 'womens',
    '6337': 'womens',
    '6351': 'womens',
    '7005': 'womens',
    '7436': 'womens',
    '7479': 'womens',
    '8816': 'womens',
  };


  // eslint-disable-next-line no-unused-vars
  const [gdId, setGdid] = useState(genderId);

  return (
    <>
      <Header />
      <div className="mens" style={{ backgroundImage: 'url(https://13-century.com/files/banner_img/mensun.webp)', backgroundPosition: 'center 30%' }}>
        <div className="videoBoxInfo text-light" style={{ paddingBottom: '50px' }}>
          <h3 className='title'>Sunglasses Men</h3>
        </div>
      </div>

      <section className="collection">

      </section>

      <section className="collection-grid">
        <div className="row row-cols-1 collection-ro ">
          {Object.keys(models).map((model) => {
            const gender = genderId[model]; // Get the gender for the current model
            return (
              <div id="pdc" className="col-6" key={model}>
                <div className="card">
                  <div className="tpd">
                    <Link className='tpimg' to={`/${gender}/${model}`} style={{ textDecoration: 'none' }}>
                      <img
                        src={hoveredImage[model] || `https://13-century.com/files/${gender}/${model}/${models[model][0]}.webp`}
                        className="card-img-top pd"
                        alt={model}
                      />
                    </Link>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title justify-content-center d-flex">Model No.{model}</h5>
                    <Flickity className={'carousel carousel-nav color-nav'} options={{ pageDots: false, dragThreshold: 8, groupCells: true, contain: true, cellAlign: 'center', wrapAround: false, }}>
                      {models[model].map((color) => (
                        <div
                          className="carousel-cell productlist"
                          key={color}
                          onMouseEnter={() => setHoveredImage((prev) => ({ ...prev, [model]: `https://13-century.com/files/${gender}/${model}/${color}.webp` }))}
                          onClick={() => setHoveredImage((prev) => ({ ...prev, [model]: `https://13-century.com/files/${gender}/${model}/${color}.webp` }))}
                        >
                          <img className='color-img' src={`https://13-century.com/files/${gender}/${model}/${color}.webp`} alt={model} />
                        </div>
                      ))}
                    </Flickity>
                  </div>

                </div>
              </div>
            );
          })}
        </div>
      </section>

      <Footer />
      <WhatsAppButton/>
    </>
  );
};

export default Menseye;
