import React, { useState, useEffect } from 'react';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import '../mens.collection.css';
import { Link } from 'react-router-dom';
import Header from '../common/head';
import Footer from '../common/footer';
import WhatsAppButton from '../common/wpbtn';
<title>13-century/Eyewears</title>


const Meneye = () => {
  const [products, setProducts] = useState([]);
  const [hoveredImage, setHoveredImage] = useState({});
  const models = {
    '1014':['C1','C2','C3'],
    '1024':['C1','C2','C3',],
    '1025':['C1','C2','C3'],
    '1028':['C1','C2','C3','C4'],
    '1030':['C1','C2','C3','C4','C5'],
    '1031':['C1','C2','C3','C4','C5'],
    '1033':['C1','C2','C3','C4','C5'],
    '5024':['C1','C2','C4','C5'],
    '6022':['C1','C3','C4','C5'],
    '7106':['C1','C3','C5'],
    '8617':['C3','C4','C5'],
    '8620':['C2','C3','C4','C5'],
    '10004':['C1','C3','C4','C5'],
    '10006':['C1','C3','C4','C5'],
    '10007':['C1','C3','C4','C5'],
    '10010':['C1','C3','C4','C5'],
    '1013':['C1','C2','C3'],
    '1027':['C1','C2','C3','C4'],
    '1029':['C1','C2','C3','C4','C5'],
    '1032':['C1','C2','C3','C4','C5'],
    '2205':['C3','C5','C6','C10'],
    '2256':['C1','C2','C4'],
    '2501':['C1','C5','C7','C8'],
    '3011':['C1','C3','C4','C6'],
    '3127':['CGRN','CTSP'],
    '5848':['C1','C3','C4','C7'],
    '6013':['C1','C4','C5','C6','C7'],
    '6016':['C1','C2','C3','C5'],
    '8273':['C1','C2','C3','C4'],
    '8868':['C1','C2','C3'],
    
  };
  const genderId = {
    '1014':'men',
    '1024':'men',
    '1025':'men',
    '1028':'men',
    '1030':'men',
    '1031':'men',
    '1033':'men',
    '5024':'men',
    '6022':'men',
    '7106':'men',
    '8617':'men',
    '8620':'men',
    '10004':'men',
    '10006':'men',
    '10007':'men',
    '10010':'men',
    '1013':'women',
    '1027':'women',
    '1029':'women',
    '1032':'women',
    '2205':'women',
    '2256':'women',
    '2501':'women',
    '3011':'women',
    '3127':'women',
    '5848':'women',
    '6013':'women',
    '6016':'women',
    '8273':'women',
    '8868':'women',
  };

  
    // eslint-disable-next-line no-unused-vars
    const [gdId, setGdid] = useState(genderId);
    
  
    return (
      <>
      <Header />
      <div className="mens" style={{ backgroundImage: 'url(https://13-century.com/files/banner_img/meneye.webp)', backgroundPosition: 'center 35%' }}>
        <div className="videoBoxInfo text-light" style={{ paddingBottom: '50px' }}>
          <h3 className='title'>Eyewear Men</h3>
        </div>
      </div>

      <section className="collection">

      </section>

      <section className="collection-grid">
        <div className="row row-cols-1 collection-ro ">
          {Object.keys(models).map((model) => {
            const gender = genderId[model]; // Get the gender for the current model
            return (
              <div id="pdc" className="col-6" key={model}>
                <div className="card">
                  <div className="tpd">
                    <Link className='tpimg' to={`/${gender}/${model}`} style={{ textDecoration: 'none' }}>
                      <img
                        src={hoveredImage[model] || `https://13-century.com/files/${gender}/${model}/${models[model][0]}.webp`}
                        className="card-img-top pd"
                        alt={model}
                      />
                    </Link>
                  </div>
                  <div className="card-body">
                    <h6 className="card-title justify-content-center d-flex">Model No.{model}</h6>
                    <Flickity className={'carousel carousel-nav color-nav'} options={{ pageDots: false, dragThreshold: 8, groupCells: true, contain: true, cellAlign: 'center', wrapAround: false, }}>
                      {models[model].map((color) => (
                        <div
                          className="carousel-cell productlist"
                          key={color}
                          onMouseEnter={() => setHoveredImage((prev) => ({ ...prev, [model]: `https://13-century.com/files/${gender}/${model}/${color}.webp` }))}
                          onClick={() => setHoveredImage((prev) => ({ ...prev, [model]: `https://13-century.com/files/${gender}/${model}/${color}.webp` }))}
                        >
                          <img className='color-img' src={`https://13-century.com/files/${gender}/${model}/${color}.webp`} alt={model} />
                        </div>
                      ))}
                    </Flickity>
                  </div>

                </div>
              </div>
            );
          })}
        </div>
      </section>

      <Footer />
      <WhatsAppButton/>
    </>
    );
};

export default Meneye;
