// src/components/Header.js
import React from 'react';
import './head.css';
import { Link } from 'react-router-dom';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.esm';
import { IoIosMenu } from "react-icons/io";

const Header = () => {
  return (
    <section className="head border-bottom">
      <div className="container_logo">
        <div className="logo">
          <Link to="/">
            <img src="https://13-century.com/files/cenweblogo.png" alt="Logo" />
          </Link>
        </div>
        <button className="menu_bar">
          <IoIosMenu size={40} className="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2" aria-label="Toggle navigation" />
        </button>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light" aria-label="Offcanvas navbar large">
        <div className="container_list">
          <div className="offcanvas offcanvas-end text-bg-light" tabIndex="-1" id="offcanvasNavbar2" aria-labelledby="offcanvasNavbar2Label">
            <div className="offcanvas-header">
              <button type="button" className="btn-close btn-close-black"  data-bs-dismiss="offcanvas"  aria-label="Close" ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav list_Items justify-content-center flex-grow-1 pe-3">
                <li className="nav-item">
                  <Link className="nav-link" to="/Collection/New Arrival">New Arrivals</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Collection/Eyewears">Eyewears</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Collection/Sunglasses">Sunglasses</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
};


export default Header;


