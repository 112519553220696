import React from 'react';
 // Custom styles for the header
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap';
import { Link } from 'react-router-dom';
import './footer.css';
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";


const Footer = () => {
    return (
        <div class="container border-top  ">
          <div className="container main">
  <div className="row">
    {/* --------------------Site Maps ---------------------------------- */}
    <div className="col-12 col-md-4 sites mb-4">
      <h5>Site Maps</h5>
      <ul className="nav flex-column">
        <li className="nav-item mb-2">
          <Link to={'/'} style={{ textDecoration: 'none', color: 'grey' }}>Home</Link>
        </li>
        <li className="nav-item mb-2">
          <Link to={'/Sunglasses/mens'} style={{ textDecoration: 'none', color: 'grey' }}>Sunglasses Men</Link>
        </li>
        <li className="nav-item mb-2">
          <Link to={'/Sunglasses/womens'} style={{ textDecoration: 'none', color: 'grey' }}>Sunglasses Women</Link>
        </li>
        <li className="nav-item mb-2">
          <Link to={'/eyewear/men'} style={{ textDecoration: 'none', color: 'grey' }}>Frames Men</Link>
        </li>
        <li className="nav-item mb-2">
          <Link to={'/eyewear/womens'} style={{ textDecoration: 'none', color: 'grey' }}>Frames Women</Link>
        </li>
      </ul>
    </div>

    {/* ------------------------------- Social Media -------------------------------- */}
    <div className="col-12 col-md-4 social mb-4">
      <h5>Social Media</h5>
      <ul className="nav flex-column">
        <li className="nav-item mb-2">
          <a href="https://www.instagram.com/13century_eyewear/" className="nav-link p-0 text-body-secondary">
            Instagram
          </a>
        </li>
        <li className="nav-item mb-2">
          <a href="https://www.facebook.com/13centuryeywear" className="nav-link p-0 text-body-secondary">
            Facebook
          </a>
        </li>
      </ul>
    </div>

    {/* -------------------------- Subscribe ------------------------- */}
    <div className="col-12 col-md-4 subscribe">
      <form>
        <div className="text-center flex-column flex-sm-row w-100 gap-2">
          <p>Subscribe to our newsletter. Monthly digest of what's new and exciting from us.</p>
          <label htmlFor="newsletter1" className="visually-hidden">
            Email address
          </label>
          <input id="newsletter1" type="email" className="form-control mb-2" placeholder="Enter your email address" />
          <button className="btn btn-primary" type="button">
            Subscribe
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

        {/* ------------------------------------------------------------------------------ */}
          <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
            <p>&copy; 13 Century All rights reserved 2024.</p>
            <ul className="list-unstyled d-flex">
              <li className="ms-3">
                <a className="link-body-emphasis" href=".">
                <FaTwitter />
                </a>
              </li>
              <li className="ms-3">
                <a className="link-body-emphasis" href=".">
                  <FaInstagram/>
                </a>
              </li>
              <li className="ms-3">
                <a className="link-body-emphasis" href=".">
                  <FaFacebook/>
                </a>
              </li>
            </ul>
          </div>

        </div>



    );
};


export default Footer


