import Header from '../common/head';
import Flickity from 'react-flickity-component';  
import 'flickity/css/flickity.css';
import './new.css';
import React, { useState } from 'react';
import 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from '../common/footer';
import WhatsAppButton from '../common/wpbtn';

const HomePage = () => {
  const [hoveredImage, setHoveredImage] = useState({});

  const models = {
    '1031': ['C1', 'C2', 'C3', 'C4', 'C5'],
    '1033': ['C1', 'C2', 'C3', 'C4', 'C5'],
    '8816': ['C1', 'C4', 'C5'],
    '6337': ['C1', 'C2', 'C35', 'C36'],
    '5024': ['C1', 'C2', 'C4', 'C5'],
    '1027':['C1','C2','C3','C4'],
    '1029':['C1','C2','C3','C4','C5'],
    '8273':['C1','C2','C3','C4'],
    '2002M': ['C1', 'C5'],
    '2155': ['C2', 'C4', 'C5'],
    '2170': ['C54', 'C56', 'C106'],
  };
  const genderId = {
    '2170': 'womens',
    '6337': 'womens',
    '8816': 'womens',
    '1031':'men',
    '1033':'men',
    '5024':'men',
    '1027':'women',
    '1029':'women',
    '8273':'women',
    '2002M': 'mens',
    '2155': 'mens',
    
  };

  const [gdId, setGdid] = useState(genderId);

  return (
    <div>
      <Header />
      <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <div className="videoBox">
              <div className="fullscreen-video-wrap">
                <video loop autoPlay preload="none" muted playsinline>
                  <source src='../files/banner.mp4'  type="video/mp4" />
                </video>
              </div>
              <div className="overlay"></div>
              <div className="videoBoxInfo" style={{ paddingBottom: '50px', paddingTop: '420px' }}>
                <a href="/Collection/New Arrival" className="videoBoxInfoBtn btn btn-outline-light" >
                  View Collection
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className='products'>
        <div className="tab">
          <div className="headline">
            <h2 className='headtext'>New Arrival</h2>
          </div>

          <Flickity className='carousel mainproducts' options={{prevNextButtons:true,dragThreshold:8,groupCells:true,pageDots:false,wrapAround:false,contain:true,cellAlign:'center',arrowShape:{x0:20,x1:60,y1:40,x2:60,y2:35,x3:25}}}>
          {Object.keys(models).map((model) => {
            const gender = genderId[model]; // Get the gender for the current model
            return (
              <div className='carousel-cell new' key={model}>
                <div className='newcard'>
                  <div className="mainImage">
                    <Link to={`/${gender}/${model}`}>
                    <img
                      src={hoveredImage[model] || `../files/${gender}/${model}/${models[model][0]}.webp`}
                      alt={model}
                    />
                    </Link>
                  </div>

                  <Flickity className='carousel-nav swatchnav' options={{prevNextButtons:true,pageDots:false,cellAlign:'center',contain:true,wrapAround:false,arrowShape:{x0:20,x1:60,y1:40,x2:60,y2:35,x3:25}}}>
                    {models[model].map((color) => (
                      <div
                        className="carousel-cell swatchcell"
                        key={color}
                        onMouseEnter={() =>
                          setHoveredImage((prev) => ({
                            ...prev,
                            [model]: `../files/${gender}/${model}/${color}.webp`,
                          }))
                        }
                        onClick={() =>
                          setHoveredImage((prev) => ({
                            ...prev,
                            [model]: `../files/${gender}/${model}/${color}.webp`,
                          }))
                        }
                      >
                        
                        <img src={`../files/${gender}/${model}/${color}.webp`} alt={model} />
                      </div>
                    ))}
                  </Flickity>

                  <div className='mdtt'>
                    <p>{model}</p>
                  </div>
                </div>
              </div>
            );
          })}
          </Flickity>
        </div>
        <div className="btnn "> <a href="/Sunglasses/mens"><button className='btn '>View Collection</button></a></div>
       
      </section>
      <section>
        <div className="container-fluid ms-0 me-0 p-0">
          <div className="row ms-0 flex-lg-nowrap overflow-hidden text-light collection-gd" style={{gap:'0px'}}>
            {[
              { img: 'womeneye.webp', text: 'Eyeglasses For Women', href: 'eyewear/womens' },
              { img: 'meneye.webp', text: 'Eyeglasses For Men', href: 'eyewear/men' },
              { img: 'newarrival.webp', text: 'Sunglasses For women', href: 'Sunglasses/womens' },
              { img: 'mensun.webp', text: 'Sunglasses For men', href: 'Sunglasses/mens' },
            ].map((item, index) => (
              <div className="col-3 p-0 position-relative bg-black small-banner" key={index}>
                <Link to={item.href} ><img className="img-fluid banner-img"src={`../files/banner_img/${item.img}`} alt="" /></Link>
                <div className="position-absolute banner-tt" style={{ bottom: '50px', padding: '0px 50px', fontWeight: 100, fontSize: '1.5rem', textTransform: 'uppercase' }}>
                  <p>{item.text}</p>
                  <a className="btn btn-outline-light banner-btn" style={{ width: '145px', height: '35px', fontWeight: 100 }} href={item.href}>
                    View Now
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer/>
      <WhatsAppButton/>
    </div>
  );
};

export default HomePage;
