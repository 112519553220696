import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import './product.css';
import Header from './head';
import WhatsAppButton from '../common/wpbtn'

const Product = () => {
  const { model } = useParams();

  const colorCodes = {
    '0759': ['C1', 'C2', 'C3', 'C4'],
    '2002M': ['C1', 'C5'],
    '2155': ['C2', 'C4', 'C5'],
    '2370': ['C1', 'C2', 'C3', 'C5', 'C6'],
    '6324': ['C37', 'C93'],
    '6334': ['C1', 'C3', 'C4'],
    '6608': ['C1', 'C3', 'C4', 'C6'],
    '8070': ['C2', 'C3'],
    '9833': ['C1', 'C4', 'C5', 'C6'],
    '9834': ['C1', 'C2', 'C5', 'C6'],
    '29035': ['C3', 'C4', 'C6'],
    '2002': ['C1', 'C5'],
    
  };

  const frameSize = {
    '0759': '56-16-145',
    '2002M': '54-18-148',
    '2155': '49-19-145',
    '2370': '56-20-143',
    '6324': '58-16-140',
    '6334': '56-16-140',
    '6608': '52-2-147',
    '8070': '58-15-141',
    '9833': '54-18-150',
    '9834': '54-16-150',
    '29035': '59-14-148',
  };

  const genderId = {
    '0759': 'Male',
    '29035': 'Male',
    '6608': 'Male',
    '6324': 'Male',
    '2002M': 'Male',
    '8070': 'Male',
    '6334': 'Male',
    '2155': 'Male',
    '9834': 'Male',
    '9833': 'Male',
    '2370': 'Male',
  };

  const [mainImage, setMainImage] = useState(`https://13-century.com/files/mens/${model}/${colorCodes[model]?.[0]}.webp`);
  const [selectedColor, setSelectedColor] = useState(colorCodes[model]?.[0] || '');
  const [indiFrame] = useState(frameSize[model] || '');
  const [gdId] = useState(genderId[model] || '');

  const imgRef = useRef(null);
  const resultRef = useRef(null);
  const lensRef = useRef(null);

  useEffect(() => {
    const img = imgRef.current;
    const result = resultRef.current;
    let lens = document.createElement('div');
    lens.setAttribute('class', 'img-zoom-lens');
    lensRef.current = lens;
    img.parentElement.insertBefore(lens, img);

    const cx = result.offsetWidth / lens.offsetWidth;
    const cy = result.offsetHeight / lens.offsetHeight;

    result.style.backgroundImage = `url('${img.src}')`;
    result.style.backgroundSize = `${img.width * cx}px ${img.height * cy}px`;

    const moveLens = (e) => {
      e.preventDefault();
      const pos = getCursorPos(e);
      let x = pos.x - lens.offsetWidth / 2;
      let y = pos.y - lens.offsetHeight / 2;

      if (x > img.width - lens.offsetWidth) x = img.width - lens.offsetWidth;
      if (x < 0) x = 0;
      if (y > img.height - lens.offsetHeight) y = img.height - lens.offsetHeight;
      if (y < 0) y = 0;

      lens.style.left = `${x}px`;
      lens.style.top = `${y}px`;

      result.style.backgroundPosition = `-${x * cx}px -${y * cy}px`;
    };

    const getCursorPos = (e) => {
      const a = img.getBoundingClientRect();
      const x = e.pageX - a.left - window.pageXOffset;
      const y = e.pageY - a.top - window.pageYOffset;
      return { x, y };
    };

    // Ensure lens moves on both mouse and touch events
    lens.addEventListener('mousemove', moveLens);
    img.addEventListener('mousemove', moveLens);
    lens.addEventListener('touchmove', moveLens);
    img.addEventListener('touchmove', moveLens);

    return () => {
      lens.removeEventListener('mousemove', moveLens);
      img.removeEventListener('mousemove', moveLens);
      lens.removeEventListener('touchmove', moveLens);
      img.removeEventListener('touchmove', moveLens);
    };
  }, [mainImage]);

  // Reapply zoom when image is loaded
  const handleImageLoad = () => {
    const img = imgRef.current;
    const result = resultRef.current;
    const lens = lensRef.current;

    const cx = result.offsetWidth / lens.offsetWidth;
    const cy = result.offsetHeight / lens.offsetHeight;

    result.style.backgroundImage = `url('${img.src}')`;
    result.style.backgroundSize = `${img.width * cx}px ${img.height * cy}px`;
  };

  const handleImageClick = (colorCode) => {
    const imageUrl = `https://13-century.com/files/mens/${model}/${colorCode}.webp`;
    setMainImage(imageUrl);
    setSelectedColor(colorCode);
  };

  return (
    <main className=''>
      <Header />
      <div className="product-item-pdc">
        <div className="wrapper-img">
          <div className="img-box">
            <img id="pd-image" ref={imgRef} src={mainImage} alt={model} onLoad={handleImageLoad} />
            <div className="img-zoom-result" ref={resultRef}></div>
          </div>
        </div>
        <div id="pdcx" className="col">
          <div className="product-item-info position-relative">
            <div className="product-title">
              <div className="d-grid">
                <h4 className="fth4">
                  Model number : <span><p className="textt"> &nbsp; {model}</p></span>
                </h4>
              </div>
              <h6 className="text-items">
                Color : <span><p className="text">&nbsp;{selectedColor}</p></span>
              </h6>
              <div className="option-group container-fluid">
                <div className="d-flex into g-3">
                  {colorCodes[model]?.map((colorCode) => (
                    <div className="col" key={colorCode}>
                      <div
                        className="carousel-cell productlist-card"
                        // onMouseEnter={() => handleImageClick(colorCode)}
                        onClick={() => handleImageClick(colorCode)}
                      >
                        <img className="color-img" src={`https://13-century.com/files/mens/${model}/${colorCode}.webp`} alt={model} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text text-items" style={{ width: 'max-content' }}>
                <h6 className="d-flex text-items">
                  Gender : <span><p className="text">&nbsp;{gdId}</p></span>
                </h6>
                <h6 className="d-flex">
                  Frame Measurement : <span><p className="text">&nbsp;{indiFrame}</p></span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppButton/>
    </main>
  );
};

export default Product;
